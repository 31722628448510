<template>
  <v-container fluid>
    <div class="mx-4">
      <v-row justify="start" class="grey-2 mt-3">
        <v-col cols="12">
          <h2 class="grey--text">Manutenção de Críticas da Operadora</h2>
        </v-col>
      </v-row>

      <v-form class="mt-7">
        <v-row justify="start">
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-text-field v-model="filter.search" solo placeholder="Pesquise">
              <template slot="prepend-inner">
                <v-autocomplete class="pa-0 pb-12" v-model="categorySearch" :items="categories" hide-details rounded :style="styleCustom"> </v-autocomplete>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-autocomplete
              v-model="filter.insuranceCarrierIds"
              :items="insuranceCarriers"
              :loading="loadingInsuranceCarriers"
              item-text="xipp_commercial_name"
              item-value="id"
              color="primary"
              solo
              multiple
              placeholder="Operadora"
              clearable
              full-width
              :search-input.sync="insuranceCarrierIdsSearch"
              @change="insuranceCarrierIdsSearch = null"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 1">
                  <span>{{ item["xipp_commercial_name"] }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  {{ formatter.getPluralText("outra", filter.insuranceCarrierIds) }}
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-autocomplete v-model="filter.classificationId" :items="classificationsFilter" item-value="id" color="primary" solo placeholder="Classificação SDI: Tudo" clearable full-width> </v-autocomplete>
          </v-col>
          <v-col :class="$vuetify.breakpoint.md ? 'pt-0' : ''" class="mb-5" cols="12" sm="6" md="8" lg="3">
            <v-row>
              <v-col>
                <v-btn style="height: 57px" block x-large dark outlined color="primary" @click="clearFilters()"> <v-icon size="26" style="font-size: 17px" class="mr-2">mdi-close</v-icon>Limpar Campos </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  style="height: 57px"
                  block
                  x-large
                  dark
                  color="primary"
                  :loading="loadingSearhButton"
                  @click="
                    loadingSearhButton = true;
                    onClickSearch();
                  "
                >
                  <v-icon style="font-size: 17px" class="mr-2">fa-search</v-icon>Pesquisar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>

      <v-card class="white elevation-1 px-5 mt-7">
        <v-row v-show="containsSomeAuthority()">
          <v-col>
            <v-btn v-if="hasAuthorityCreateClassification" class="mr-5 elevation-0 primary--text" large color="#edeff1" @click="createNewClassification()">
              <v-icon class="mr-2">mdi mdi-plus</v-icon>
              Novo
            </v-btn>
            <v-btn v-if="hasAuthorityEditClassification" large :disabled="selectedItems.length === 0 || selectedItems.length > 1" class="mr-5 elevation-0 primary--text" color="#edeff1" @click="editClassification()">
              <v-icon class="mr-2">mdi mdi-pencil</v-icon>
              Editar
            </v-btn>
            <v-btn v-if="hasAuthorityDeleteClassification" large :disabled="selectedItems.length === 0" class="mr-5 elevation-0 primary--text" color="#edeff1" @click="openExclusionDynamicModal">
              <v-icon class="mr-2">mdi-delete</v-icon>
              Excluir
            </v-btn>
            <v-btn v-if="hasAuthorityEditClassification" large :disabled="selectedItems.length === 0" class="elevation-0 primary--text" color="#edeff1" @click="classifyCriticism">
              <v-icon class="mr-2">mdi mdi-tag</v-icon>
              Classificar Críticas
            </v-btn>
          </v-col>

          <!-- //! Funcionalidade que entrará posteriormente -->
          <!-- <v-col align="end">
            <v-btn class="elevation-0">
              <v-icon class="">mdi-download</v-icon>
            </v-btn>
          </v-col> -->
        </v-row>

        <v-data-table
          v-model="selectedItems"
          class="elevation-0 mt-8"
          show-select
          item-key="id"
          hide-default-header
          :loading="loadingTable"
          :server-items-length="totalPages"
          :options.sync="options"
          :headers="headers"
          :items="criticims"
          :page="page"
          :items-per-page="itemsPerPage"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 75, 100],
            'items-per-page-text': 'Registros por página',
          }"
          @update:options="changePage($event)"
        >
          <template v-slot:[`header`]="{ props }">
            <thead>
              <tr>
                <th
                  v-for="(header, index) in props.headers"
                  :key="index"
                  :style="{
                    'background-color': '#ffffff !important',
                    'text-align': 'start',
                    width: index === 0 ? '50px' : index === 5 ? '20%' : 'auto',
                  }"
                >
                  <v-simple-checkbox v-if="index === 0" color="primary" v-model="isMultSelect" @click="handleSelectionChange" />
                  <label class="label primary--text">
                    {{ header.text }}
                  </label>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:[`item.insuranceCarrierId`]="{ item }">
            {{ formatedInsuranceCarrier(item.insuranceCarrierId) }}
          </template>
          <template v-slot:[`item.field`]="{ item }">
            {{ getFieldNameByValue(item.field) || '-' }}
          </template>
          <template v-slot:[`item.criticismCode`]="{ item }">
            {{ item.criticismCode ? item.criticismCode : "-" }}
          </template>
          <template v-slot:[`item.description`]="{ item }">
            {{ item.description ? item.description : "-" }}
          </template>
          <template v-slot:[`item.message`]="{ item }">
            {{ item.message ? item.message : "-" }}
          </template>
          <template v-slot:[`item.carrierCriticism`]="{ item }">
            {{ item.carrierCriticism ? item.carrierCriticism : "-" }}
          </template>
          <template v-slot:[`item.classification`]="{ item }">
            <v-btn :style="{ cursor: 'auto' }" rounded class="newPrimary--text elevation-0" color="#ebf1f3">
              <v-icon size="20" class="mr-2">{{ formatedClassification(item).icon }}</v-icon>
              {{ formatedClassification(item).text }}
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <v-footer class="mt-10">
      <v-row class="d-flex justify-end">
        <v-col cols="12" md="3" lg="2">
          <v-btn x-large block outlined color="textPrimary" @click="onClickBack()" style="height: 57px"> Voltar </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <ExclusionDynamicModal :show="showExclusionDynamicModal" :loadingConfirm="loadingConfirm" @close="closeExclusionDynamicModal" @confirm="confirmExclusionDynamicModal">
      <template slot="header"> Excluir Classificação </template>
      <template slot="content">
        {{ countRegisterSelected }}
        <p>{{ confirmationMessage }}</p>
      </template>
    </ExclusionDynamicModal>

    <SnackbarCustomize ref="SnackbarCustomize" />

    <InsuranceCriticismFormModal
      ref="InsuranceCriticismFormModal"
      :insuranceCarriers="insuranceCarriers"
      :classificationOptions="classificationsOptions"
      :countRegisterSelected="countRegisterSelected"
      :rule="rule"
      @confirm="confirmInsuranceCriticismFormModal"
      @emitAlertCustom="emitAlertCustom"
    />
  </v-container>
</template>

<script>
import Rules from '@/shared/validators/formRules';
import ConstantsMixin from '@/shared/mixins/constants/constantsMixin';
import Formatters from '@/shared/formatters/formatters';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import InsuranceCriticismFormModal from '@/components/InsuranceCriticismManagement/InsuranceCriticismFormModal.vue';
import AuthorityManager from '@/shared/models/authority-manager.model';
import ExclusionDynamicModal from '@/components/ExclusionDynamicModal/ExclusionDynamicModal.vue';
import sdiServiceManager from '@/services-http/sdi/SDIServiceManager';
import DataSourceService from '@/services-http/sdi/DataSourceService';

export default {
  name: 'InsuranceCriticismManagement',
  components: { SnackbarCustomize, InsuranceCriticismFormModal, ExclusionDynamicModal },
  data: () => ({
    insuranceCarrierIdsSearch: null,
    loadingInsuranceCarriers: false,
    isRHProtegido: false,
    isMultSelect: false,
    showExclusionDynamicModal: false,
    loadingSearhButton: false,
    loadingConfirm: false,
    loadingTable: false,
    hasAuthorityCreateClassification: false,
    hasAuthorityEditClassification: false,
    hasAuthorityDeleteClassification: false,

    categorySearch: '',
    confirmationMessage: '',
    countRegisterSelected: '',
    page: 1,
    totalPages: 0,
    itemsPerPage: 25,

    options: {},
    payloadFilter: {},
    payloadClassify: {},
    payloadInsertAndEdit: {},
    filter: {
      search: null,
      field: null,
      description: null,
      criticismCode: null,
      insuranceCarrierIds: [],
      classificationId: null,
      carrierCriticism: null,
      message: null,
    },
    insuranceCriticismFormData: {
      type: 'create',
      data: {
        insuranceCarrierId: null,
        field: null,
        criticismCode: null,
        description: null,
        message: null,
        carrierCriticism: null,
        classification: null,
      },
    },

    selectedItems: [],
    insuranceCarriers: [],
    classificationsFilter: [],
    classificationsOptions: [],
    criticims: [],
    categories: [
      {
        text: 'Todos',
        value: '',
      },
      {
        text: 'Campo',
        value: 'field',
      },
      {
        text: 'Código de crítica',
        value: 'criticismCode',
      },
      {
        text: 'Descrição',
        value: 'description',
      },
      {
        text: 'Mensagem',
        value: 'message',
      },
      {
        text: 'Classificação operadora',
        value: 'carrierCriticism',
      },
    ],
    headers: [
      {
        text: 'Operadora',
        align: 'start',
        sortable: false,
        value: 'insuranceCarrierId',
      },
      {
        text: 'Campo',
        align: 'start',
        sortable: false,
        value: 'field',
      },
      {
        text: 'Código',
        align: 'start',
        sortable: false,
        value: 'criticismCode',
      },
      {
        text: 'Descrição',
        align: 'start',
        sortable: false,
        value: 'description',
      },
      {
        text: 'Mensagem',
        align: 'start',
        sortable: false,
        value: 'message',
      },
      {
        text: 'Classificação operadora',
        align: 'start',
        sortable: false,
        value: 'carrierCriticism',
      },
      {
        text: 'Classificação SDI',
        align: 'start',
        sortable: false,
        value: 'classification',
      },
    ],
    dataSourceService: null,
    criticismFields: [],
  }),

  mixins: [InsuranceCarriersMixin, ConstantsMixin, VerifyRoutesMixin],

  watch: {
    selectedItems(newValue) {
      this.updateSelectedItemsCount(newValue);
    },
  },

  computed: {
    styleCustom() {
      const common = 'height: 52px;';

      const styleMap = {
        criticismCode: 'width: 200px',
        carrierCriticism: 'width: 250px',
        description: 'width: 155px',
        message: 'width: 155px',
      };

      return `${common} ${styleMap[this.categorySearch] || 'width: 150px'}`;
    },
  },

  async mounted() {
    this.loadAuthorityMovement();
    this.getClassifications();
    this.onClickSearch();
    await this.loadCriticismField();
    await this.getInsuranceCarriers();
  },

  created() {
    this.options.mounted = true;
    this.rule = new Rules();
    this.formatter = new Formatters();
    this.dataSourceService = new DataSourceService();
    this.serviceController();
  },

  methods: {
    serviceController() {
      this.carrierCriticismService = sdiServiceManager.getCarrierCriticismService();
    },
    async loadAuthorityMovement() {
      this.hasAuthorityCreateClassification = AuthorityManager.getAuthorityCreateInsuranceCriticismClassification();
      this.hasAuthorityEditClassification = AuthorityManager.getAuthorityEditInsuranceCriticismClassification();
      this.hasAuthorityDeleteClassification = AuthorityManager.getAuthorityDeleteInsuranceCriticismClassification();
      // this.hasAuthorityDownloadClassification = AuthorityManager.getAuthorityDownloadInsuranceCriticismClassification();
    },
    containsSomeAuthority() {
      return this.hasAuthorityCreateClassification || this.hasAuthorityEditClassification || this.hasAuthorityDeleteClassification;
    },
    handleSelectionChange() {
      if (this.isMultSelect) {
        this.selectAllItems();
      } else {
        this.clearSelection();
      }
    },
    selectAllItems() {
      this.selectedItems = this.criticims;
    },
    clearSelection() {
      this.selectedItems = [];
    },
    changePage(event) {
      if (!this.options.mounted) {
        this.itemsPerPage = event.itemsPerPage;
        this.page = event.page;
        this.onClickSearch();
      }

      if (this.options.mounted) {
        this.options.mounted = false;
      }
    },
    formatedClassification(item) {
      const defaultOption = {
        text: 'Não classificado',
        icon: 'mdi mdi-progress-clock',
      };
      const result = this.classificationsOptions.find((element) => element && element.id === item.classificationId) || defaultOption;

      if (item.notClassified) {
        return defaultOption;
      }

      return result;
    },
    clearFilters() {
      this.categorySearch = '';
      this.filter = {
        search: null,
        field: null,
        description: null,
        code: null,
        insuranceCarrierIds: [],
        classificationId: null,
        carrierCriticism: null,
        message: null,
      };
    },
    async onClickSearch() {
      this.isMultSelect = false;
      this.selectedItems = [];
      this.loadingTable = true;
      this.validationFilters();
      const search = this.getFilteredParams();

      const query = this.adjustedPagination(search);

      const searchParams = new URLSearchParams(query);

      this.carrierCriticismService
        .FindBy(searchParams)
        .then((response) => {
          if (response && response.data && response.data.content) {
            this.criticims = response.data.content;
            this.totalPages = response.data.totalElements;
            this.loadingSearhButton = false;
            this.loadingTable = false;
          }
        })
        .catch(() => {
          this.loadingTable = false;
          this.loadingSearhButton = false;
          this.emitAlertCustom('error', 'Erro ao buscar críticas');
        });
    },
    adjustedPagination(search) {
      const query = {
        ...search,
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: 'id,desc',
      };

      return query;
    },
    validationFilters() {
      const validCategories = ['criticismCode', 'field', 'message', 'description', 'carrierCriticism'];
      const updatedFilter = { ...this.filter };
      if (validCategories.includes(this.categorySearch)) {
        updatedFilter[this.categorySearch] = updatedFilter.search;
      }

      if (this.categorySearch !== '') {
        updatedFilter.search = null;
      }

      if(updatedFilter.classificationId === 'Não classificado'){
        updatedFilter.classificationId = null
        updatedFilter.notClassified = true;
      }

      this.payloadFilter = updatedFilter;
    },
    async loadCriticismField() {
      await this.dataSourceService.FindAll().then((response) => {
        const fields = response.data.find((item) => item.value === 'movimentacao');
        this.criticismFields = fields.columns;
      });
    },
    getFilteredParams() {
      return Object.entries(this.payloadFilter)
        .filter(([key, value]) => value && value !== null && value !== '' && value.length !== 0)
        .reduce((element, [key, value]) => ({ ...element, [key]: value }), {});
    },
    createNewClassification() {
      this.resetValueInsuranceForm();
      this.selectedItems = [];
      this.isMultSelect = false;
      this.insuranceCriticismFormData.type = 'create';
      this.$refs.InsuranceCriticismFormModal.open(this.insuranceCriticismFormData);
    },
    editClassification() {
      this.resetValueInsuranceForm();
      if (this.selectedItems.length === 1) {
        this.insuranceCriticismFormData = {
          type: 'edit',
          data: this.selectedItems[0],
        };

        this.$refs.InsuranceCriticismFormModal.open(this.insuranceCriticismFormData);
      }
    },
    resetValueInsuranceForm() {
      this.insuranceCriticismFormData.data = {};
      this.insuranceCriticismFormData.type = '';
    },
    classifyCriticism() {
      this.resetValueInsuranceForm();
      this.insuranceCriticismFormData.type = 'classify';
      this.$refs.InsuranceCriticismFormModal.open(this.insuranceCriticismFormData);
    },
    openExclusionDynamicModal() {
      this.showExclusionDynamicModal = true;
      const countSelectedItems = this.selectedItems.length;
      this.confirmationMessage = this.getConfirmationMessage(countSelectedItems);
    },
    getConfirmationMessage(count) {
      let suffix = '';
      if (count > 1) {
        suffix = 's';
      }
      return `Tem certeza que deseja excluir este${suffix} registro${suffix}?`;
    },
    closeExclusionDynamicModal() {
      this.showExclusionDynamicModal = false;
    },
    confirmExclusionDynamicModal() {
      this.loadingConfirm = true;
      const ids = this.selectedItems.map((item) => item.id);
      this.carrierCriticismService
        .Delete(ids)
        .then(() => {
          this.emitAlertCustom('success', 'Registro excluído com sucesso');
          this.loadingConfirm = false;
          this.closeExclusionDynamicModal();
          this.onClickSearch();
        })
        .catch(() => {
          this.loadingConfirm = false;
          this.emitAlertCustom('error', 'Erro ao excluir registro');
        });
    },
    getFieldNameByValue(value) {
      const fieldFounded = this.criticismFields.find((field) => field.value === value);
      if (fieldFounded) {
        return fieldFounded.label;
      }
      return value;
    },
    confirmInsuranceCriticismFormModal(form) {
      if (form) {
        if (this.insuranceCriticismFormData.type === 'classify') {
          this.requestClassify(form);
        }
        if (this.insuranceCriticismFormData.type === 'create') {
          this.requestCreateClassification(form);
        }
        if (this.insuranceCriticismFormData.type === 'edit') {
          this.requestEditClassification(form);
        }
      }
    },
    requestClassify(form) {
      const carrierCriticismIds = this.selectedItems.map((item) => item.id);
      this.payloadClassify = { carrierCriticismIds, classificationId: form.classificationId };

      this.carrierCriticismService
        .UpdateClassification({ ...this.payloadClassify })
        .then(() => {
          this.emitAlertCustom('success', 'Classificação realizada com sucesso');
          this.closeInsuranceCriticismFormModal();
          this.onClickSearch();
          this.$refs.InsuranceCriticismFormModal.loadingConfirmButton = false;
        })
        .catch(() => {
          this.$refs.InsuranceCriticismFormModal.loadingConfirmButton = false;
          this.emitAlertCustom('error', 'Erro ao classificar críticas');
        });
    },
    requestCreateClassification(form) {
      this.carrierCriticismService
        .Insert(form)
        .then(() => {
          this.emitAlertCustom('success', 'Classificação criada com sucesso');
          this.closeInsuranceCriticismFormModal();
          this.onClickSearch();
          this.$refs.InsuranceCriticismFormModal.loadingConfirmButton = false;
        })
        .catch(() => {
          this.emitAlertCustom('error', 'Erro ao criar classificação');
          this.$refs.InsuranceCriticismFormModal.loadingConfirmButton = false;
        });
    },
    requestEditClassification(form) {
      this.carrierCriticismService
        .Update(form)
        .then(() => {
          this.emitAlertCustom('success', 'Classificação editada com sucesso');
          this.closeInsuranceCriticismFormModal();
          this.onClickSearch();
          this.$refs.InsuranceCriticismFormModal.loadingConfirmButton = false;
        })
        .catch(() => {
          this.emitAlertCustom('error', 'Erro ao editar classificação');
          this.$refs.InsuranceCriticismFormModal.loadingConfirmButton = false;
        });
    },
    updateSelectedItemsCount(newValue) {
      let suffix = '';
      if (newValue.length > 1) {
        suffix = 's';
      }
      this.countRegisterSelected = `${newValue.length} Registro${suffix} selecionado${suffix}`;
    },
    getClassifications() {
      this.carrierCriticismService.FindAll().then(async (response) => {
        if (response && response.data) {
          this.classificationsOptions = await response.data;
          this.mapClassifications();
        }
      });
    },
    mapClassifications() {
      if (this.classificationsOptions) {
        this.classificationsOptions = this.classificationsOptions.map((classification) => {
          let icon = '';
          let text = '';

          switch (classification.name.toLowerCase()) {
            case 'external':
              text = 'Restritiva Externa';
              icon = 'mdi mdi-account-cog-outline';
              break;
            case 'internal':
              text = 'Restritiva Interna';
              icon = 'mdi mdi-cancel';
              break;
            case 'informative':
              text = 'Informativa';
              icon = 'mdi mdi-information-slab-circle-outline';
              break;
            case 'decisive':
              text = 'Decisiva';
              icon = 'far fa-question-circle';
              break;
            case 'inactive':
              text = 'Inativa';
              icon = 'mdi mdi-minus-circle-outline';
              break;
            default:
              break;
          }

          return {
            text,
            icon,
            id: classification.id,
          };
        });
        this.classificationsFilter = [...this.classificationsOptions]

        this.classificationsFilter.push({
          text : 'Não classificado',
          icon: 'mdi mdi-progress-clock',
          notClassified: true
        })
      }
    },
    formatStringToTitleCase(form) {
      Object.keys(form).forEach((key) => {
        if (typeof form[key] === 'string') {
          form[key] = this.formatter.formatToTitleCase(form[key]);
        }
      });

      return form;
    },
    closeInsuranceCriticismFormModal() {
      this.$refs.InsuranceCriticismFormModal.close();
    },
    onClickBack() {
      this.redirectRouter('HomeAllMenus', { isRHProtegido: this.isRHProtegido });
    },
    formatedInsuranceCarrier(id) {
      let text = '-';
      if (this.insuranceCarriers && this.insuranceCarriers.length > 0) {
        text = this.insuranceCarriers.find((carrier) => carrier.id === id);

        if (text) {
          return text.xipp_commercial_name;
        }
      }
      return '-';
    },
    emitAlertCustom(type, message) {
      this.$refs.SnackbarCustomize.open(type, message);
    },
  },
};
</script>

<style scoped>
.white-background {
  background-color: #ffffff !important;
}
</style>
